import { createI18n } from "vue-i18n";

const messages = {
  nl: {
    nav: {
      menu: "menu",
      close: "sluit",
      signup: "aanmelden",
      login: "inloggen",
      contact: {
        title: "Contact",
        question: "Heb je een vraag? Neem vrijblijvend contact met ons op.",
        call: "Bel ons op 085-8228262",
      },
      home: "Home",
      about: "Over Lenn",
      howDoesItWork: "Hoe werkt Lenn?",
      forWho: "Voor wie?",
      forRefferers: "Informatie voor verwijzers",
      tarifs: "Tarieven en vergoedingen",
      contactsLocations: "Contact en locaties",
      forEmployers: "Voor werkgevers",
      workForLenn: "Werken bij Lenn",
      news: "Nieuws",
      footer: {
        privacyStatement: "Privacyverklaring",
        cookieStatement: "Cookieverklaring",
        qualitySt: "Kwaliteitsstatuut",
        complaints: "Klachtenregeling",
        conditionsTerms: "Algemene voorwaarden"
      }
    },
    ourLocations: "Onze locaties",
    homeView: {
      first: {
        title: "Jouw psycholoog in de buurt",
        content: "<p>Binnen enkele dagen een goed gesprek met een psycholoog? Met èchte aandacht voor jou en de wereld om je heen.</p><p>Misschien ben je al na één gesprek geholpen. Heb je meer nodig? Samen met ons werk je aan jouw thema’s, gericht op hoofd én lijf.</p><p>Ons aanbod is kortdurend en intensief en helpt bij het vinden van een nieuw perspectief. Dat doen we met gesprekken, digitaal en in het groen. Zo brengen we de zorg dichtbij, tot in jouw broekzak.</p><p>Je krijgt begeleiding van psychologen en een fysiotherapeut of psycho-motorisch therapeut. Want ook bewegen heeft invloed op jouw mentale gezondheid.</p>"
      },
      second: {
        content: "<p>Bij Lenn geloven we dat de zorg voor mentale gezondheid in Nederland beter kan. Zonder wachtlijsten en zonder dat we mentaal ongemak altijd zien als een ziekte of aandoening. We focussen op wat helpend is voor jou.</p><p>Persoonlijk, intensief en kortdurend.</p><p>Met de nieuwste inzichten en innovaties bieden we een frisse kijk op jouw mentale én fysieke gezondheid.</p>"
      }
    },
    importantValues: {
      title: "Dit vinden we belangrijk",
      inspired: {
        title: "Inspirerend",
        content: "Met onze frisse kijk op gezondheid willen we inspireren. We willen de zorg anders organiseren. Onze medewerkers inspireren we om het beste uit zichzelf te halen. We willen bijdragen aan meer verbinding in de maatschappij. En we inspireren jou om stappen te zetten in je herstel."
      },
      equal: {
        title: "Gelijkwaardig",
        content: "Onze psychologen staan naast je, als professional en als mens. Wij geloven dat het succes van onze zorg niet alleen wordt bepaald door de kennis, maar juist ook door de relatie tussen cliënt en professional. Het draait om de verbinding, vanuit professionele nabijheid. We zijn ervan overtuigd dat dit bijdraagt aan jouw eigen regie en het vermogen om te herstellen."
      },
      green: {
        title: "Groen",
        content: "Onze locaties zijn lekker groen en gevuld met veel planten. Want groen doet goed. Als het kan, gaan we naar buiten. De natuur in. Uit onderzoek van Wageningen University & Research (WUR) blijkt dat een natuurlijke omgeving op allerlei manieren bijdraagt aan de mentale gezondheid. Zo vermindert het stress en stimuleert om te bewegen."
      }
    },
    aboutView: {
      title: "Over Lenn",
      pink: {
        title: "Een frisse kijk op jouw gezondheid",
        intro: "We geloven dat de zorg voor mentale gezondheid in Nederland beter kan. Zonder wachtlijsten en met focus op wat helpend is voor jou.",
        content: "<p>Met de nieuwste inzichten en innovaties bieden we een frisse kijk op jouw mentale én fysieke gezondheid. Zo krijg jij een nieuw perspectief.</p><p>Onze ervaren psychologen en specialisten helpen jou op weg. Persoonlijk, intensief en niet langer dan nodig. Wij geven jou een zetje om jouw eigen situatie te verbeteren. Het vermogen zit in jezelf.</p>",
      },
      twoVlak: {
        title1: "Geen hokjes",
        content1: "<p>Bij Lenn denken we niet in hokjes. Het mentaal ongemak dat jij ervaart, zien we niet alleen als een ziekte of aandoening. We geloven dat het niet altijd helpend is om te denken vanuit strakke kaders en labels.</p><p>Liever maken we echt contact en sluiten we aan bij jouw behoeftes. We kijken niet alleen naar jouw klachten, maar vooral naar achterliggende thema’s en de context. Daarmee gaan we aan de slag.</p>",
        title2: "Zorg dichtbij, tot in je broekzak",
        content2: "<p>Alle Lenn-zorg bieden we ‘blended’ aan. We combineren gesprekken buiten of in de spreekkamer met het beste dat digitale toepassingen ons te bieden hebben. Zoals inzicht via monitoring en dagboeken. Uitleg en informatie op je telefoon. En oefeningen op een app die je kunt doen in jouw eigen omgeving en onderweg. Kortom, zorg tot in je broekzak.</p><p>We kunnen de zorg ook volledig digitaal aanbieden. Bijvoorbeeld omdat er geen Lenn-locatie in jouw regio is, je minder mobiel bent, of gewoon omdat je dat prettiger vindt. Natuurlijk blijf je altijd welkom voor een fysiek gesprek.</p>"
      },
      team: {
        title: "Ons team",
        content: "<p>Ons multidisciplinaire team bestaat uit ervaren en hoogopgeleide zorgprofessionals.</p><p>Je wordt begeleid door een klinisch of gezondheidzorg-psycholoog en eventueel een tweede psycholoog. Onze fysiotherapeut helpt je bij het opstellen van jouw beweegplan. Dat geeft jou de zekerheid dat we jouw zorgvraag van alle kanten bekijken.</p><p>Samen brengen we jouw lichaam en geest in balans, met behulp van gesprekken, online contacten en beweging. Daarbij verliezen we nooit de mens uit het oog.</p>"
      }
    },
    contactView: {
      title: "Contact en locaties",
      contactForm: {
        contact: "Contact",
        name: "Naam",
        namePlaceholder: "Jouw naam",
        nameErrormessage: "Vul svp je naam in...",
        email: "E-mailadres",
        emailPlacerholder: "mail&commat;domein.nl",
        emailErrormessage: "Vul svp je e-mailadres in...",
        phoneNumber: "Telefoonnummer",
        phoneNumberPlaceholder: "telefoonnummer",
        phoneErrormessage: "",
        message: "Stel je vraag",
        messagePlacerholder: "Je bericht...",
        messageErrormessage: "Wat is je bericht...",
        send: "verstuur"
      },
    },
    tabVlak: {
      tabs: {
        title: "Dit is onze frisse aanpak",
        goedgesprekItem: "Goed Gesprek",
        monitoringItem: "Monitoring",
        situatieItem: "Jouw situatie in kaart",
        focusItem: "Focus",
        bewegenItem: "Bewegen",
        terugblikItem: "Terugblik",
      },
      goedGesprek: {
        title: "Goed Gesprek",
        content: "<p>Binnen enkele dagen kun je bij ons terecht voor een goed gesprek. Dat kan volledig digitaal of op één van onze huiselijke locaties. Samen onderzoeken we wat er bij jou speelt. Wat heb je zelf al gedaan? Welke mogelijkheden heb je? Wat is belangrijk voor jou?<br />Dit doen we vanuit de principes van Single Session Therapy. Soms kun je na dit gesprek zelf alweer verder.</p><p>Jouw naasten hebben een belangrijke rol in jouw leven. Neem daarom gerust iemand mee naar het gesprek.</p>"
      },
      monitoring: {
        title: "Monitoring",
        content: "<p>Blijkt uit het gesprek dat bepaalde onderwerpen meer aandacht nodig hebben? Om gericht aan de slag te gaan vragen we je om een dagboek bij te houden in een app op je telefoon. Daarin vul je in hoe het met je gaat. Dat geeft jezelf en ons een goed beeld van je situatie. </p><p>Die periode geeft jou de ruimte om na te denken over wat er in het eerste gesprek is besproken en of je inderdaad meer hulp wilt.</p>"
      },
      yourSituation: {
        title: "Jouw situatie in kaart",
        content: "<p>In een tweede gesprek gaan we dieper in op jouw klachten, de verbanden daartussen en het onderliggende thema. Samen maken we een plan om te werken aan verbetering.</p>"
      },
      focus: {
        title: "Focus",
        content: "<p>Blijkt uit het gesprek dat bepaalde onderwerpen meer aandacht nodig hebben? Dan gaan we in het tweede gesprek dieper in op jouw klachten, de verbanden daartussen en het onderliggende thema. Samen maken we een plan om te werken aan verbetering.</p><p>Geen langdurig traject, maar kortdurend en intensief. We praten en bewegen in de buitenlucht, bij ons op kantoor en ondersteunen je digitaal. Zo zijn we in de buurt als je ons nodig hebt.</p><p>Liever volledig digitaal? Dat kan ook.</p><p>Je kunt vertrouwen op wetenschappelijk bewezen technieken. Zoals cognitieve gedragstherapie (CGT), acceptance en commitment therapy (ACT), EMDR, oplossingsgerichte therapie en elementen uit de schematherapie. We kiezen een methode die bij jou past.</p><p>We staan naast je, als professional en mens. En stimuleren je om zelf de stappen te zetten om te herstellen. Zodra je vooruitgang ervaart op jouw thema, zal je ook verbetering merken op andere gebieden.</p>"
      },
      movement: {
        title: "Bewegen",
        content: "<p>Lichaam en geest met elkaar zijn verbonden en beïnvloeden elkaar. Daarom hebben we ook aandacht voor je lijf. Door op een gezonde en fijn manier te bewegen, leer je steeds beter voelen en herkennen wat belangrijk voor je is.</p>"
      },
      recall: {
        title: "Terugblik",
        content: "<p>Aan het eind evalueren we jouw situatie. Ben je voldoende op weg geholpen? Ons doel is om jou te ondersteunen zolang dat nodig is, maar niet langer dan noodzakelijk.</p><p>Wil je op een later moment in je leven een volgende stap zetten? Dan ben je van harte welkom. Zo houden we de zorg betaalbaar en is onze zorg maximaal effectief.</p>"
      },
    },
    approachView: {
      twoVlak: {
        title: "Hoe werkt lenn?"
      },
      pink: {
        title: "Onze aanpak",
        intro: "Wij zijn er om jou op weg te helpen. Dat begint met een Goed Gesprek. Heb je meer nodig? Samen kiezen we de vorm die het beste bij jou past. Lees hieronder meer over onze aanpak."
      }
    },
    bigVerloopTitle: "Welkom bij Lenn,<br/>een frisse kijk op jouw gezondheid",
    centerQuote: {
      quote1: {
        content: "\"Het was fijn om snel geholpen te kunnen worden. Zowel de gesprekken als de oefeningen die we hebben gedaan hebben mij enorm geholpen. Ik zie mijn toekomst weer met vertrouwen tegemoet.\"",
        from: "Michel, 39jr"
      },
      quote2: {
        content: "\"Door de combinatie van gesprekken en digitale contacten kon ik snel en intensief aan de slag. Samen en ondersteund door de psycholoog en fysiotherapeut. Heel prettig.\"",
        from: "Pieter, 40jr"
      },
      quote3: {
        content: "\"We kwamen heel snel bij de kern, waardoor ik wist wat mij te doen stond.\"",
        from: "Anke, 54jr"
      },
      quote4: {
        content: "\"Vanaf het eerste moment was er ruimte voor mijn verhaal. Het contact voelde heel vertrouwd.\"",
        from: "Arno, 29jr"
      }
    },
    vlakImageBackground: {
      self: "Gun jezelf een nieuw perspectief.<br/>Kom vandaag nog in actie.",
      employer: "Gun je medewerker een nieuw perspectief<br/>Kom vandaag nog in actie.",
      cta: {
        employers: "Vraag een offerte aan",
        approach: "Hoe werkt lenn?",
        signup: "Ja, ik wil graag een gesprek"
      }
    },
    vlakBullets: {
      vlakBullet1: "Binnen enkele dagen een Goed Gesprek",
      vlakBullet2: "Kortdurend en intensief",
      vlakBullet3: "Gesprekken op locatie, in het groen én digitaal",
      vlakBullet4:  "Werken aan jouw mentale én fysieke gezondheid",
      cta: "Boek direct een <br />Goed Gesprek"
    },
    fourButtons: {
      signUp: "Aanmelden",
      iHaveReferral: "Ik heb een verwijzing van mijn huisarts",
      meOrEmployerPays: "Ik of mijn werkgever betaalt",
      referral: "Verwijzen",
      signupClient: "Cliënt aanmelden",
      wantToKnowMore: "Meer weten?"
    },
    employersView: {
      title: "Voor werkgevers",
      pink: {
        intro: "Wist je dat zo’n 17% van alle werknemers stress- en burn-out gerelateerde klachten ervaart? En dat maar liefst 43% van de mensen ooit te maken krijgt met een psychische aandoening.",
        content: "Als een medewerker uitvalt met mentale klachten, duurt het gemiddeld 279 dagen voordat die is hersteld. De wachttijden voor de geestelijke gezondheidszorg zijn enorm lang, soms jaren. Lenn pakt dit anders aan. Zonder wachtlijst en met focus op wat écht helpt. Met gesprekken, digitaal contact en een beweegplan."
      },
      scrollText: {
        healthWork: {
          title: "Goed en gezond aan het werk",
          content: "De kans is groot dat ook jouw organisatie te maken krijgt met uitval door mentale of langdurig fysieke klachten. Dit ondanks alle inspanningen en goed werkgeverschap. Dat komt omdat de (mentale) gezondheid van medewerkers wordt bepaald door zoveel verschillende factoren dat jouw invloed beperkt is. Lenn helpt jou als werkgever om je medewerkers goed en gezond aan het werk te helpen én te houden."
        },
        goedeGesprekken: {
          title: "Met Goede Gesprekken",
          content: "We houden Goede Gesprekken met je medewerkers. Daarin bespreken we hoe het gaat en waar iemand tegenaan loopt. Zo krijgen we tijdig signalen en kunnen we mentale klachten bij medewerkers voorkomen of verminderen."
        },
        focusRoute: {
          title: "Focus-route",
          content: "<p>Is iemand toch uitgevallen? Dan zijn we direct beschikbaar om deze medewerker te ondersteunen in zijn herstel. Dat begint met een Goed Gesprek, gevolgd door een Focus-route. Dit is een kort, intensief traject van enkele weken waarin we kijken naar het netwerk van factoren die samen de mentale klachten veroorzaken. Daarmee pakken de bron van de klachten aan en niet slechts de symptomen.</p><p>We delen geen inhoudelijke informatie over de Focus-route en de situatie van de werknemer met jou als werkgever. Want voor het herstel van de medewerker is het belangrijk dat die volledig open kan zijn. Wat de medewerker met ons bespreekt, valt onder ons beroepsgeheim en is volledig vertrouwelijk.</p>"
        },
        quickRecover: {
          title: "Snel hulp = sneller herstellen",
          content: "We geloven dat werken gezond maakt én houdt. We stimuleren cliënten daarom om zoveel mogelijk aan het werk te blijven, maar dan op een gezonde manier. Wij helpen niet alleen jou en je medewerkers, maar jij helpt ook om goede betaalbare zorg te leveren."
        },
        costsOfCare: {
          title: "Kosten van onze zorg",
          content: "<p>Een Goed Gesprek met een medewerker kost €295,- excl. btw. Op basis van dit gesprek maken we een inschatting van de mate waarin jouw werknemer zal profiteren van onze ondersteuning. Met toestemming van de medewerker delen we dit met jou als werkgever.</p><p>De kosten voor een Focus-route zijn afhankelijk van de situatie. Je ontvangt een offerte op basis van de uitkomsten van het eerste gesprek.  Er is geen wachtlijst. Het doel van de Focus-route is een snelle en volledige re-integratie.</p><p>Wil je meer weten of een offerte aanvragen? Neem dan contact op via info&commat;lenn-zorgt.nl of laat jouw medewerker zich aanmelden.</p>"
        }
      },
    },
    forWhoView: {
      title: "Voor wie?",
      pink: {
        intro: "Bij Lenn kun je terecht met mentale en langdurige fysieke klachten. Ook als jouw situatie of klachten ingewikkeld zijn."
      },
      scrollImage: {
        title: "Denk aan klachten zoals:"
      },
      pink2: {
        intro: "Binnen enkele dagen na jouw aanmelding krijg je bij ons een goed gesprek. Samen ontdekken we wat jij nodig hebt om weer verder te kunnen. Heb je na dit gesprek meer nodig? Dan kun je een Focus-traject volgen.",
      },
      bullets: {
        fear: "Angst",
        somber: "Somberheid/depressie",
        stress: "Stress",
        trauma: "Trauma",
        onzeker: "Onzekerheid/laag zelfbeeld",
        pieker: "Piekeren",
        perfectionmisme: "Perfectionisme",
        aandachtsProblemen: "Aandachtsproblemen",
        ongezond: "Ongezonde gewoontes",
        aanpassingsProblemen: "Aanpassingsproblemen",
        relatieProblemen: "Problemen in relaties met anderen",
        slaapProblemen: "Slaapproblemen",
        burnOut: "Burn-out",
        verwerking: "(Rouw)verwerking",
        chronische: "Chronische pijn",
        conditieEnLeefstijl: "Slechte conditie of leefstijlproblemen",
        seksueel: "Seksuele problemen",
        chronischVermoeid: "Chronische vermoeidheid",
        longCovid: "Long-covid",
        zingeving: "Zingevingsvraagstukken"
      }
    },
    newsView: {
      title: "Nieuwsberichten"
    },
    referrersView: {
      title: "Informatie voor verwijzers",
      pink: {
        intro: "Lenn is de nieuwe zorgaanbieder voor mentale gezondheidszorg. Met een multidisciplinair team van ervaren professionals aan boord. Zonder wachtlijst en met focus op wat écht helpend is voor de cliënt. Hiervoor brengen we de nieuwste inzichten en zorginnovaties samen in één uniek proces."
      },
      scrollView: {
        goedGesprek: {
          title: "Snel een Goed Gesprek",
          content: "Cliënten krijgen bij ons binnen enkele dagen een gesprek met een ervaren klinisch psycholoog of GZ-psycholoog. De cliënt bepaalt zelf waar we dat gesprek voeren: digitaal of op één van onze groene locaties. We houden dit gesprek volgens de principes van Single Session Therapy. Doel van dit gesprek is om te ontdekken wat de cliënt nodig heeft. Misschien kan jouw cliënt daarna al zelf verder."
        },
        focusTraject: {
          title: "Focus-traject",
          content: "Is er méér nodig? Dan bieden we een Focus-route. Dit is een kort, maar intensief traject waarin we aan de slag gaan op basis van Netwerkdiagnostiek. Hierbij brengen we het netwerk van symptomen, mentale of fysieke klachten in kaart. Dit helpt ons om de bron van de klachten aan te pakken, in nauwe samenwerking tussen cliënt en professional."
        },
        vergoeding: {
          title: "Vergoeding",
          content: "Is er sprake van een DSM-classificatie? Dan valt het aanbod onder de verzekerde basiszorg. Dit betekent dat de zorg afhankelijk van de gekozen zorgverzekeraar en zorgpolis geheel of gedeeltelijk (50-80%) wordt vergoed. Wanneer we op het juiste moment ondersteuning bieden, kan jouw cliënt daarna weer zelfstandig verder."
        },
        inExCriteria: {
          title: "In- en exclusiecriteria",
          content: "Lenn hanteert ruime inclusiecriteria voor volwassenen. We bieden echter geen crisiszorg en geen forensische zorg aan. Ook begeleiden we geen cliënten onder de 18 jaar, cliënten met een hoge mate van crisisgevoeligheid, cliënten met een IQ <85 of cliënten die de Nederlandse of Engelse taal slecht beheersen."
        },
        thinkAlong: {
          title: "We denken graag met je mee",
          content: "Vanuit Lenn werken we graag intensief samen met verwijzers en regionale partners. We zijn eenvoudig bereikbaar voor overleg en staan altijd klaar om mee te denken waar dat is gewenst."
        },
        referralViaZorgdomain: {
          title: "Verwijzing: via Zorgdomein",
          content: "Wij ontvangen graag verwijzingen via Zorgdomein. We zijn te vinden op 'Lenn Rotterdam' en 'Lenn Groningen'. Cliënten kunnen ook zichzelf aanmelden via het aanmeldformulier op onze website, waarbij ze de verwijsbrief kunnen toevoegen."
        }
      },
    },
    tarifsView: {
      title: "Tarieven en vergoedingen",
      pink: {
        intro: "<p class=\"withlink\">Lenn is een instelling voor mentale gezondheidszorg. We hanteren de tarieven die de Nederlandse Zorgautoriteit (NZa) heeft vastgesteld voor onze zorg. De tarieven verschillen per zorgprofessional. Bekijk <a href=\"/assets/Tarieven Lenn 2025.xls\" target=\"_blank\">hier</a> de tarieven 2025, inclusief rekentool. Voor werkgevers hebben wij aparte prijzen.</p>"
      },
      scrollView: {
        criteria: {
          title: "Criteria voor verzekerde zorg",
          content: "Voldoen jouw klachten aan de criteria van een classificatie op basis van de DSM-5 en heb je een geldige verwijzing van een arts? Dan valt ons aanbod onder de verzekerde zorg. Wat jouw zorgverzekeraar vergoedt is afhankelijk van de door jouw gekozen zorgverzekeraar en polis."
        },
        ownRisk: {
          title: "Eigen risico",
          content: "In alle gevallen betaal je het resterende eigen risico aan de zorgverzekeraar. Neem contact op met jouw zorgverzekeraar om na te gaan welk bedrag van jouw eigen risico nog over is."
        },
        contractsProviders: {
          title: "Zorgverzekeraars waar wij contracten mee hebben",
          content: "Voor 2025 heeft Lenn contracten gesloten met een aantal zorgverzekeraars. Deze vind je onderaan de pagina. Dit betekent dat de zorg door hen volledig wordt vergoed. Wij sturen de factuur rechtstreeks aan de zorgverzekeraars. De zorgverzekeraar verrekent met jou het resterende eigen risico.",
        },
        uncontractedHealthInsurers: {
          title: "Ongecontracteerde verzekeraars",
          content: "<p>Voor de andere zorgverzekeraars geldt dat de zorg gedeeltelijk wordt vergoed. De hoogte van de vergoeding is afhankelijk van de door jouw gekozen polis. Ook hierbij is het eigen risico van toepassing op het gedeelte dat jouw zorgverzekeraar vergoedt.</p><p>Wil je zeker zijn van je zaak? Neem dan contact op met jouw zorgverzekeraar om te bespreken welk deel zij gaan vergoeden.</p>",
        },
        coulance: {
          title: "Coulanceregeling",
          content: "Wanneer jouw zorgverzekeraar slechts een deel van de rekening betaalt, hanteren wij een coulance-regeling. Dit betekent dat wij 100% van de NZa-tarieven in rekening brengen, maar dat jij maar 90% van het factuurbedrag aan ons hoeft te betalen. De overige 10% van de factuur wordt kwijtgescholden na ontvangst van jouw deelbetaling."
        },
        general: {
          title: "Algemeen",
          content: "Bepaalde klachten, zoals burn-out, rouw of slaapproblemen, voldoen niet aan de criteria voor een DSM-classificatie. In dit geval is een vergoeding door de zorgverzekeraar niet mogelijk. Wel kunnen we samen kijken of jouw werkgever bereid is om (een deel van) de kosten op zich te nemen.</p><p>Voorafgaand aan een Focus-route bespreken we de kosten voor jouw specifieke aanbod met je. Je kunt dan een goede inschatting maken of het aanbod bij jou past.</p>"
        },
        invoicing: {
          title: "Facturatie via Infomedics",
          content: "<p>De factuur voor de digitale en fysieke contacten ontvang je maandelijks via Infomedics. Dit doen zij in eerste instantie digitaal.</p><p>Vervolgens dien je de factuur in bij jouw zorgverzekeraar en ontvang je de gedeeltelijke vergoeding. De betalingstermijn is 30 dagen.</p>"
        },
        cancelApt: {
          title: "Afspraak afzegggen",
          content: "<p>Bij Lenn werken we met geplande afspraken. Dat geldt voor de gesprekken, maar ook voor de digitale contacten. Zo weet je altijd wat je kunt verwachten.</p><p>Wanneer de afspraak niet door kan gaan dan vragen we je dit tijdig, minimaal 48 uur van te voren, telefonisch of via een bericht aan info&commat;lenn-zorgt.nl, te melden.</p><p>Wanneer je je niet op tijd afmeldt mag Lenn de kosten voor de afspraak deels in rekening brengen. De kosten zijn € 75,- per gemiste afspraak en € 25,- per gemist digitaal feedback-moment en worden niet door de verzekeraars vergoed.</p>"
        },
      }
    },
    workAtLennView: {
      title: "Werken bij Lenn",
      pink: {
        intro: "<p>Betere zorg voor mentale gezondheid begint bij professionals die met plezier en aandacht hun vak uitoefenen.</p><p>Jouw mening doet er toe. We sluiten aan bij jouw persoonlijke kwaliteiten en interesses. En bieden je de ruimte om te werken op een manier die past bij jou en onze gezamelijke missie.</p><p>Hieronder vind je onze vacatures.</p>"
      },
      jobs: {
        title: "Onze vacatures",
        region: "Regio"
      },
      twoVlak: {
        text: "<p>Ben je enthousiast over Lenn, maar staat jouw rol er niet bij? Of zijn we nog niet actief in jouw regio? Ook dan komen we graag met je in contact!</p><p>We zijn een nieuwe aanbieder mèt grote ambities. Binnen enkele maanden kunnen we jou hard nodig hebben!</p>",
        cta: "Kom in contact"
      }
    },
    signUpView: {
      title: "Aanmelden",
      introStarted: "<p>Meld je hier aan voor zorg bij Lenn.</p><p>Na ontvangst van jouw aanmelding krijg je toegang tot ons beveiligde platform. Binnen dit platform kun je veilig met ons communiceren. Daarnaast wordt jouw zorg bij Lenn via het Minddistrict platform inhoudelijk ondersteund. </p><p>Wij nemen telefonisch contact met je op om een afspraak te maken.</p>",
      introFinished: "<p>Gelukt! Jouw aanmelding is door ons ontvangen. Je ontvangt een mail met inloggegevens voor het beveiligde Minddistrict platform.</p><p>Wij nemen telefonisch contact met je op om een afspraak te maken.</p>",
      form: {
        title: "Jouw informatie",
        firstName: {
          label: "Voornaam",
          placeholder: "Wat is je voornaam"
        },
        intermediates: {
          label: "Tussenvoegsel",
          placeholder: "Tussenvoegsels"
        },
        lastName: {
          label: "Achternaam",
          placeholder: "Wat is je achternaam"
        },
        initials: {
          label: "Voorletters",
          placeholder: "Wat zijn je voorletters?"
        },
        gender: {
          label: "Geslacht",
          placeholder: "Kies je geslacht",
          pickSentence: "Kies je geslacht",
          man: "Man",
          woman: "Vrouw"
        },
        dateOfBirth: {
          label: "Geboortedatum",
          placeholder: "Je geboortedatum"
        },
        email: {
          label: "E-mailadres",
          placeholder: "Wat is je e-mailadres"
        },
        street: {
          label: "Straat",
          placeholder: "Wat is je straatnaam"
        },
        houseNumber: {
          label: "Huisnummer",
          placeholder: "Wat is je huisnummer"
        },
        houseNumberAddition: {
          label: "Huisnr toevoeging",
          placeholder: "Toevoegingen (bijvoorbeeld: B)"
        },
        postalCode: {
          label: "Postcode (1234AA)",
          placeholder: "Wat is je postcode (bijvoorbeeld: 1234AA)"
        },
        city: {
          label: "Woonplaats",
          placeholder: "Wat is je woonplaats"
        },
        phoneNumber: {
          label: "Telefoonnummer (0612345678)",
          placeholder: "Wat is je telefoonnummer (bijvoorbeeld: 0612345678)"
        },
        notes: {
          label: "Heb je een verwijzing?",
          placeholder: "Heb je een verwijzing?",
          drReferral: "Ik heb een verwijzing via mijn arts",
          employersPays: "Nee, ik of mijn werkgever betaalt zelf",
          willContactDr: "Nee, maar ik neem contact op met mijn arts"
        },
        errorMessage: {
          subTitle: "Probeer het nogmaals of bel ons op 085-8228262 of Whatsapp via 06-496 829 78"
        },
        termsConditions: "Ja, ik ben akkoord met de <a href=\"/algemene-voorwaarden\" target=\"_blank\">Algemene Voorwaarden</a> en de <a href=\"/privacyverklaring\" target=\"_blank\">privacyverklaring</a> van Lenn.",
        send: "Verstuur!"
      }
    },
    footer: {
      contact: "Contact",
      questionContent: "Heb je een vraag?<br/>Neem vrijblijvend contact met ons op.",
      callUs: "Bel ons op",
      or: "of"
    }
  },
  en: {
    nav: {
      menu: "menu",
      close: "close",
      signup: "sign up",
      login: "login",
      contact: {
        title: "Contact",
        question: "Do you have a question? Feel free to ask for more information without any obligation.",
        call: "Call us at 085-8228262",
      },
      home: "Home",
      about: "About Lenn",
      howDoesItWork: "How does Lenn work?",
      forWho: "For whom?",
      forRefferers: "Information for referrers",
      tarifs: "Rates and compensation",
      contactsLocations: "Contact and locations",
      forEmployers: "For employers",
      workForLenn: "Work for Lenn",
      news: "News",
      footer: {
        privacyStatement: "Privacy statement",
        cookieStatement: "Cookie statement",
        qualitySt: "Quality statute",
        complaints: "Complaints procedure",
        conditionsTerms: "General terms and conditions"
      }
    },
    ourLocations: "Our locations",
    homeView: {
      first: {
        title: "Discover a brighter path with Lenn, your local psychologist",
        content: "<p>Within days, embark on a journey that starts with a meaningful conversation tailored just for you, with genuine care for your well-being and the world around you.</p><p>One session can already make a difference. If you need it, there is more support available, focusing on both your mind and body.</p><p>Our approach is brief, but intensive. And helps you to gain a fresh perspective. Engage in insightful conversations, whether in person, online, or amidst the tranquility of nature. We bring care to your fingertips, making it easily accessible.</p><p>Experience guidance from our team of psychologists and a dedicated physiotherapist. This because we believe that your body plays a crucial role in enhancing your mental well-being.</p>"
      },
      second: {
        content: "<p>Lenn believes that mental healthcare in the Netherlands can be improved, with no waiting lists and a perspective that does not always see mental discomfort as an illness or condition. We focus on what is helpful to you.</p><p>Personal, intensive, and short.</p><p>With the latest insights and innovations, we provide a fresh perspective on your mental and physical well-being.</p>"
      }
    },
    importantValues: {
      title: "We consider this important",
      inspired: {
        title: "Inspiring",
        content: "With our fresh perspective on wellbeing, we want to inspire. We want to change health care and organise it differently. We inspire our employees to achieve their potential. We want to foster stronger relationships and unity within society. And we want to inspire you to start taking steps in your recovery."
      },
      equal: {
        title: "Equality",
        content: "Our psychologists are with you, as professionals and as human beings. We believe that the success of our care is not just due to knowledge, but also due to the relationship between the client and the professional. The focus is on connection, with a foundation of a close and professional approach. We are convinced that this will contribute to your own control and ability to recover."
      },
      green: {
        title: "Green",
        content: "Our locations include a lot of green, always being filled with plants. Because green does good. Whenever it is possible, we go outside, amidst the tranquility of nature. In research from Wageningen University & Research (WUR) it becomes apparent that being in nature contributes to mental wellbeing in numerous ways. It helps to lower stress and encourages physical activity."
      }
    },
    aboutView: {
      title: "About Lenn",
      pink: {
        title: "A fresh perspective on your health",
        intro: "We believe that mental healthcare in the Netherlands can be improved - no waiting lists, with a focus on what is helpful for you.",
        content: "<p>With the latest insights and innovations, we offer a fresh outlook on your mental and physical well-being. This provides you with a new perspective.</p><p>Our experienced psychologists and specialists guide you personally, intensively, and for no longer than necessary. We empower you to improve your own situation; you've got the key.</p>",
      },
      twoVlak: {
        title1: "No boxes",
        content1: "<p>Lenn does not believe in categorizing. The mental discomfort you're experiencing isn't merely seen as an illness or condition. We recognize that thinking in rigid frameworks and labels isn't always helpful.</p><p>Instead, we aim for genuine connection, aligning with your needs. We do not just focus on your symptoms but delve into underlying themes and context. That's where we start.</p>",
        title2: "Care at your fingertips",
        content2: "<p>All Lenn services are offered in a blended format. We combine face-to-face or outdoor sessions with the best that digital treatments can offer. This includes insights through monitoring and diaries, education and information on your phone, as well as exercises on an app that you can do in your own environment and on the go.</p><p>We can also provide our care entirely digitally. This might be helpful if there's no Lenn location in your region, you're not able to travel, or simply because you prefer it. Of course, you're always welcome for a physical conversation.</p>"
      },
      team: {
        title: "Our team",
        content: "<p>Our multidisciplinary team consists of experienced and highly educated care professionals.</p><p>You will be guided by a clinical or health care psychologist and possibly a second psychologist. Our physiotherapist assist you in a developing your own exercise plan. This gives you the assurance that we are considering your care request from all perspectives.</p><p>Together we bring your mind and body into balance, using conversations, online contact, and movement. In doing so, we never lose sight of the person.</p>"
      }
    },
    contactView: {
      title: "Contact and locations",
      contactForm: {
        contact: "Contact",
        name: "Name",
        namePlaceholder: "Your name",
        nameErrormessage: "Please fill in your name...",
        email: "Email address",
        emailPlacerholder: "mail&commat;domain.com",
        emailErrormessage: "Please fill in your email address...",
        phoneNumber: "Phone number",
        phoneNumberPlaceholder: "phone number",
        phoneErrormessage: "Please fill in your phone number...",
        message: "Ask your question",
        messagePlacerholder: "Your message...",
        messageErrormessage: "What is your message...",
        send: "send"
      },
    },
    tabVlak: {
      tabs: {
        title: "This is our fresh approach",
        goedgesprekItem: "Meaningful Conversation",
        monitoringItem: "Monitoring",
        situatieItem: "Mapping out your situation.",
        focusItem: "Focus",
        bewegenItem: "Moving",
        terugblikItem: "Reflection",
      },
      goedGesprek: {
        title: "Meaningful Conversation",
        content: "<p>Within a few days you can come to us for a Meaningful Conversation. This can be fully digital or at one of our homey locations. Together we get to the bottom of what is affecting you. What have you already done yourself? Which possibilities do you have? What is of significant importance to you?<br />We do this following the principles of the Single Session Therapy. Sometimes one conversation is enough to get you on the right track.</p><p>Your loved ones play a key role in your life, so don't hesitate to bring someone to this meeting.</p>"
      },
      monitoring: {
        title: "Monitoring",
        content: "<p>If during the conversation it appears that specific topics do need more attention, we will ask you to keep a diary in an app on your phone. In this diary you can describe how you feel, which gives both you and us a good overview of your situation. </p><p>That time gives you the space to think about what has been said in the first meeting and if you do indeed want more help.</p>"
      },
      yourSituation: {
        title: "Mapping out your situation",
        content: "<p>During a second meeting we will futher delve into your issues, the connections between them and the underlying themes. Together we will develop a plan to work towards improvement.</p>"
      },
      focus: {
        title: "Focus",
        content: "<p>Is it evident from the conversation that certain topics need more attention? Then we will futher explore these during the second meeting, delving into your symptoms, the connections between them and the underlying themes. Together we will develop a plan to work towards improvement.</p><p>No lengthy process, but brief and intensive. We talk and move amidst the tranquility of nature, at our homey office and digitally. In this way we are always nearby when you need us. Do you prefer fully digital? That is also completely possible.</p><p>You can trust the scientifically proven techniques, like cognitive behaviour therapy (CBT), acceptance and commitment therapy (ACT), EMDR, solution-focused therapy and elements of schema therapy. We choose a method that suits you.</p><p>We will stand beside you, as a professional and a human being. We stimulate you to take the steps to recover. The moment you experience progress within your topics, you will also notice improvement in other areas.</p>"
      },
      movement: {
        title: "Moving",
        content: "<p>Body and mind are connected and influence each other, which is why we also focus on your physique. By moving in a healthy and pleasant way, you learn to feel better and recognise what is of significant importance to you.</p>"
      },
      recall: {
        title: "Reflection",
        content: "<p>Towards the end we evaluate your situation. Have you been sufficiently helped to get started? Our aim is to guide you as long as needed, but not longer than necessary.</p><p>Would you like to take a next step later in your life? Then you are completely welcome. This approach allows us to keep care affodrable and ensure that the effectiviness is maximized.</p>"
      },
    },
    approachView: {
      twoVlak: {
        title: "How does Lenn work?"
      },
      pink: {
        title: "Our approach",
        intro: "We are here to assisty you in getting on the right track. This starts with a meaningful conversation. Do you need more? Together we choose the way that fits you the best. Read more about our approach below."
      }
    },
    bigVerloopTitle: "Welcome to Lenn,<br/>a fresh perspective on your wellbeing",
    centerQuote: {
      quote1: {
        content: "\"It was very pleasant to be helped so quickly. Both the conversations we had and the exercises we did helped me enormously. Now I can look at my future with confidence.\"",
        from: "Michel, aged 39"
      },
      quote2: {
        content: "\"Thanks to the combination of in-person conversations and digital interactions, I was able to dive into my personal journey quickly and intensively. Collaborating with the psychologist and physiotherapist made the experience very pleasant and supportive.\"",
        from: "Pieter, aged 40"
      },
      quote3: {
        content: "\"We quickly reached the core, enabling me to understand what steps I needed to take.\"",
        from: "Anke, aged 54"
      },
      quote4: {
        content: "\"From the very first moment, there was room for me to share my story. The connection felt comforting and familiar.\"",
        from: "Arno, aged 29"
      }
    },
    vlakImageBackground: {
      self: "Give yourself a new perspective.<br/>Make the first step today.",
      employer: "Give your employee a new perspective.<br/>Make the first step today.",
      cta: {
        employers: "Request a quote",
        approach: "How does Lenn work?",
        signup: "Yes, I would like a conversation"
      }
    },
    vlakBullets: {
      vlakBullet1: "A meaningful conversation within a few days",
      vlakBullet2: "Brief, but intensive",
      vlakBullet3: "Conversations, both digitally and amidst the tranquility of nature",
      vlakBullet4:  "Working on your mental and physical wellbeing",
      cta: "Book a <br />Meaningful Conversation"
    },
    fourButtons: {
      signUp: "Sign up",
      iHaveReferral: "I have a referral from my general practitioner",
      meOrEmployerPays: "My employer or I will cover the cost",
      referral: "Referral",
      signupClient: "Sign up client",
      wantToKnowMore: "Want to know more?"
    },
    employersView: {
      title: "For employers",
      pink: {
        intro: "Did you know that around 17% of all employees experience stress and burn-out related symptoms? And that no less than 43% of people will have to deal with a psychological disorder at one point in their life?",
        content: "If an employee is unable to work due to mental health problems, it will take around 279 days until they are recovered. Mental health care is experiencing extremely long waiting lists, sometimes even taking years. Lenn has a different approach. Without waiting lists and with focus on what really helps. With conversations, digital contact, and an exercise plan."
      },
      scrollText: {
        healthWork: {
          title: "Working well and healthy",
          content: "There is a large chance that your organization also experiences staff outage due to mental or long-term physical symptoms. Despite all the efforts and high-quality employer ship. This is due to the fact that the (mental) health of employees is determined by numerous factors, limiting your influence. Lenn assists you as an employer in ensuring that your employees are both working well and maintaining in good health."
        },
        goedeGesprekken: {
          title: "With meaningful conversations",
          content: "We will have meaningful conversations with your employees, in which we will discuss how someone is feeling and what issues someone is facing. In such a way, we receive signals in time, which allows us to prevent or improve mental complaints of employers."
        },
        focusRoute: {
          title: "Focus-route",
          content: "<p>If someone does end up being absent, we are immediately available to support that employee in their recovery. This begins with a meaningful conversation, followed by a focus-route. This is a short, intensive program lasting a few weeks in which we examine the network of factors contributing to the mental health issues. This approach addresses the root cause of the problems rather than just the symptoms.</p><p>We do not share any detailed information about the focus-route and the employee's situation with you as the employer. This is since it is important for the employee's recovery that they can be completely open. What the employee discusses with us is confidential and protected by professional secrecy.</p>"
        },
        quickRecover: {
          title: "Quick help = quick recovery",
          content: "We believe that working promotes and maintains good health. Therefore, we encourage clients to stay at work as much as possible, but in a healthy way. We don't only assist you and your employees, but you also help provide good, affordable care."
        },
        costsOfCare: {
          title: "Cost of our care",
          content: "<p>A meaningful conversation with an employee costs €295,- excl. BTW. Based on this conversation, we will assess the extent to which your employee will benefit from our futher support. With the employee's consent, we will share this assessment with you as the employer.</p><p>The costs for a focus-route depend on the specific situation. You will receive a quote based on the outcomes of the initial conversation. There is no waiting list. The goal of the focus-route is a quick and complete reintegration.</p><p>Would you like to know more or request a quote? Please contact us at info&commat;lenn-zorgt.nl or have your employee sign up.</p>"
        }
      },
    },
    forWhoView: {
      title: "For whom?",
      pink: {
        intro: "Lenn is available for people with mental and long-term physical symptoms. Even if your situation or symptoms are complex."
      },
      scrollImage: {
        title: "Think of symptoms such as:"
      },
      pink2: {
        intro: "Within a few days after you sign up you will be scheduled for a meaningful conversation. Together we will discover what you need to be able to move on. Do you need more than one conversation? Then we will continue with a focus-route.",
      },
      bullets: {
        fear: "Fear",
        somber: "Sadness/depression",
        stress: "Stress",
        trauma: "Trauma",
        onzeker: "Insecurity/low self-esteem",
        pieker: "Worrying",
        perfectionmisme: "Perfectionism",
        aandachtsProblemen: "Concentration issues",
        ongezond: "Unhealthy habits",
        aanpassingsProblemen: "Adjustment problems",
        relatieProblemen: "Issues in relation to others",
        slaapProblemen: "Sleeping difficulties",
        burnOut: "Burn-out",
        verwerking: "(Grief) processing",
        chronische: "Chronical pain",
        conditieEnLeefstijl: "Poor physical condition or lifestyle problems",
        seksueel: "Sexual issues",
        chronischVermoeid: "Chronic fatigue",
        longCovid: "Long-covid",
        zingeving: "existential issues"
      }
    },
    newsView: {
      title: "News"
    },
    referrersView: {
      title: "Information for referrers",
      pink: {
        intro: "Lenn is the new healthcare provider for mental health services. With a multidisciplinary team consistent of experienced professionals. No waiting list and complete focus on what really helps the client. With this aim in mind, we combine new insights and care innovations within one unique process."
      },
      scrollView: {
        goedGesprek: {
          title: "A meaningful conversation quickly",
          content: "Within a few days, the clients will be able to have a meaningful conversation with an experienced clinical psychologist or health care psychologist. It will completely be up to the client where this conversation will take place: digitally or at one of our green locations. We use principles of the Single Session Therapy during this conversation, aiming to discover what the client really needs. Maybe your client will be able to continue the road to recovery independently after just one conversation."
        },
        focusTraject: {
          title: "Focus-route",
          content: "Is more needed? Then we will offer a focus-route consisting of a short, but intensive process in which we work based on the network diagnosistics. By mapping the network of symptoms, mental or physical complaints, we can tackle the root of the problem with a close collaboration between client and professional."
        },
        vergoeding: {
          title: "Compensation",
          content: "Is there mention of a DSM-classification? Then the offer falls under basic health insurance. This means that the care is fully or partly (50-80%) compensated depending on the chosen health insurance provider and policy. When we offer guidance that the right time, your client can continue independently."
        },
        inExCriteria: {
          title: "In- and exclusion criteria",
          content: "Lenn applies wide inclusion criteria for adults. However, we do not offer crisis care nor do we provide forensic care. Furthermore, we do not guide clients under the age of 18, clients with a high level of crisis sensitivity, clients with an IQ <85 or clients with a low Dutch or English language proficiency."
        },
        thinkAlong: {
          title: "We like to think along with you",
          content: "At Lenn we like to collaborate intensively with referrers and regional partners. We are easily reachable for consultation and are always available to think along with you whenever needed."
        },
        referralViaZorgdomain: {
          title: "Referral: via Zorgdomein",
          content: "We like to receive referrals via Zorgdomein. You can find us at 'Lenn Rotterdam' and 'Lenn Groningen'. Clients can also sign up independently via the sign up form on our website, in which they can claso include a referral letter."
        }
      },
    },
    tarifsView: {
      title: "Fees and compensation",
      pink: {
        intro: "<p class=\"withlink\">Lenn is a mental health care facility, due to which we utilize the fees that the 'Nederlands zorgautoriteit' (NZa) decided upon. The fees differ based on the care professional. Read the fees <a href=\"/assets/Tarieven Lenn 2025.xls\" target=\"_blank\">here</a>, which includes a calculationtool that give an estimation of the costs. For employers we have separate fees.</p>"
      },
      scrollView: {
        criteria: {
          title: "Criteria for insured care",
          content: "Do your symptoms meet the criteria of a classification based on the DSM-5 and do you have a valid referral by a physician? Then our care is included in insured care. Which part of the costs are compensated is dependent on your chosen health insurance provider and policy."
        },
        ownRisk: {
          title: "Deductible",
          content: "In all cases you must pay the remaining deductible to your health insurance provider. We advise you to get in contact with your health insurance provider to see how much is left of your deductible."
        },
        contractsProviders: {
          title: "Health insurance providers with which we have contracts",
          content: "For 2025 Lenn has made an agreement with several health insurance providers. You can find an overview of them below this page. As a result, the care from Lenn will be completely covered by these insurers. Lenn will send the declarations directly to them, and they will settle the remaining deductible with you.",
        },
        uncontractedHealthInsurers: {
          title: "Uncontracted health care insurers",
          content: "For other health care insurers this means that they will only partly cover care at Lenn. The amount of the reimbursement depends on the policy you have. Here too, the deductible applies to the part that your health insurer covers."
        },
        coulance: {
          title: "Goodwill arrangement",
          content: "Whenever your health insurance provider only covers part of your invoice, we use a goodwill arrangement. This means that we will charge 100% of the Nza-fees, but you only must pay 90% to us. The remaining 10% will be waived upon receipt of your partial payment."
        },
        general: {
          title: "General",
          content: "<p>Certain symptoms, like burn-out, grief or sleep issues, do not meet the criteria for a DSM-classification. In this case compensation from a health insurance provider is not possible. What we can do is collaboratively see if your employer is willing to cover (a part of) the costs.</p><p>Prior to a focus-route we will discuss the costs for your specific procedure with you. As a result, you can make a well-considered decision about the suitability of that offer.</p>"
        },
        invoicing: {
          title: "Billing through Infomedics",
          content: "<p>The invoice including the online and in-person contacts will be made available to you monthly via Infomedics. Initially they will be doing this digitally.</p><p>Subsequently, you submit the invoice to your health insurance provider, and you will receive the partly compensation. The payment term is 30 days.</p>"
        },
        cancelApt: {
          title: "Cancelling an appointment",
          content: "<p>At Lenn we work with planned meetings. This relates to the conversations, but also the digital contacts. Consequently, you are always aware of what you can expect.</p><p>Whenever you are unable to be present at the meeting, we ask you to let us know in a timely manner, at least 48 hours beforehand, by telephone or via a message at info&commat;lenn-zorgt.nl.</p><p>If you do not cancel the meeting time, we will charge you with part of the scheduled costs. For a missed meeting you will be charged € 75,- and for a missed digital feedback-moment you will be charged € 25,-. These costs will not be compensated by your health insurance.</p>"
        }
      }
    },
    workAtLennView: {
      title: "Work at Lenn",
      pink: {
        intro: "<p>Betere zorg voor mentale gezondheid begint bij professionals die met plezier en aandacht hun vak uitoefenen.</p><p>Jouw mening doet er toe. We sluiten aan bij jouw persoonlijke kwaliteiten en interesses. En bieden je de ruimte om te werken op een manier die past bij jou en onze gezamelijke missie.</p><p>Hieronder vind je onze vacatures.</p>"
      },
      jobs: {
        title: "Onze vacatures",
        region: "Regio"
      },
      twoVlak: {
        text: "<p>Ben je enthousiast over Lenn, maar staat jouw rol er niet bij? Of zijn we nog niet actief in jouw regio? Ook dan komen we graag met je in contact!</p><p>We zijn een nieuwe aanbieder mèt grote ambities. Binnen enkele maanden kunnen we jou hard nodig hebben!</p>",
        cta: "Kom in contact"
      }
    },
    signUpView: {
      title: "Sign up",
      introStarted: "<p>Sign up here for care by Lenn.</p><p>After receiving your application, you will get access to our secured platform. Within this platform you can safely communicate with us. Additionally, your care by Lenn will be supported using the Minddistrict platform. </p><p>We will contact you by telephone to set up a meeting.</p>",
      introFinished: "<p>Success! We received your application. You will receive an email with login details for the secured Minddistrict platform.</p><p>We will contact you by telephone to set up a meeting.</p>",
      form: {
        title: "Your information",
        firstName: {
          label: "First name",
          placeholder: "What is your first name?"
        },
        intermediates: {
          label: "Intermediate",
          placeholder: "Intermediate"
        },
        lastName: {
          label: "Last name",
          placeholder: "What is your last name?"
        },
        initials: {
          label: "Initials",
          placeholder: "What are your initials?"
        },
        gender: {
          label: "Gender",
          placeholder: "Choose your gender",
          pickSentence: "Choose your gender",
          man: "Man",
          woman: "Woman"
        },
        dateOfBirth: {
          label: "Date of birth",
          placeholder: "Your date of birth"
        },
        email: {
          label: "Email address",
          placeholder: "What is your email address"
        },
        street: {
          label: "Street",
          placeholder: "What is your street name?"
        },
        houseNumber: {
          label: "House number",
          placeholder: "What is your house number"
        },
        houseNumberAddition: {
          label: "House number addition",
          placeholder: "Addition (for example: B)"
        },
        postalCode: {
          label: "Postal code (1234AA)",
          placeholder: "What is your postal code (for example: 1234AA)"
        },
        city: {
          label: "City",
          placeholder: "What is your residence city?"
        },
        phoneNumber: {
          label: "Phone number (0612345678)",
          placeholder: "What is your phone number (for example: 0612345678)"
        },
        notes: {
          label: "Do you have a referral?",
          placeholder: "Do you have a referral?",
          drReferral: "I have a referral from my general practitioner",
          employersPays: "No, my employer or I will cover the costs",
          willContactDr: "No, but I will contact my general practitioner"
        },
        errorMessage: {
          subTitle: "Try again or call us on 085-8228262 or Whatsapp via 06-496 829 78"
        },
        termsConditions: "Yes, I accept the <a href=\"/algemene-voorwaarden\" target=\"_blank\">Algemene Voorwaarden</a> and the <a href=\"/privacyverklaring\" target=\"_blank\">privacyverklaring</a> van Lenn.",
        send: "Send!"
      }
    },
    footer: {
      contact: "Contact",
      questionContent: "Do you have a question?<br/>Contact us.",
      callUs: "Call us at",
      or: "or"
    }
  }
}

const i18n = createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages
})

export default i18n